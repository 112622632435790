import React from "react"
import { Helmet } from "react-helmet"
import RDStationConection from "../utils/RDStationConnection"

import Header from "../components/Header/Header"
import SectionOne from "../components/Contact/SectionOne/SectionOne"
// import SectionTwo from "../components/Contact/SectionTwo/SectionTwo"
import Footer from "../components/Footer/Footer"
import NeoronWidget from "../utils/NeoronWidget"

export default function Contact() {
  React.useEffect(() => {
    RDStationConection.init()
    NeoronWidget.init()
  }, [])
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Página não encontrada</title>
        <link rel="canonical" href="https://fab.work/contact/" />
        {/* Global site tag (gtag.js) - Google Ads: 668243363 */}
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=AW-668243363"
        ></script>
        {/* Event snippet for Enviar formulário de lead conversion page */}
        <script
          dangerouslySetInnerHTML={{
            __html: `
            gtag('event', 'conversion', {'send_to': 'AW-668243363/IGwzCMXaj9sCEKOr0r4C'});
            `,
          }}
        />
      </Helmet>
      <Header />
      {/*<div style={{ display: "none" }}></div>*/}
      <SectionOne />
      <Footer />
    </>
  )
}
